import { DefaultSeoProps } from 'next-seo';

const URL = 'https://www.unigrades.co.uk';
const TITLE = `UK University Grades Calculator ${new Date().getFullYear()}`;
const DESCRIPTION = 'A free comprehensive UK university grade calculator.';

const defaultSeo: DefaultSeoProps = {
  title: TITLE,
  description: DESCRIPTION,
  openGraph: {
    title: TITLE,
    description: DESCRIPTION,
    url: URL,
    type: 'website',
    locale: 'en_GB',
    siteName: TITLE,
  },
};

export default defaultSeo;
