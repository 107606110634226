var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"381c2fc4ab70deb9ff33018146c9bd85d525a336"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { NEXT_IS_PRODUCTION_ENV, NEXT_ENV } from '@edt-monorepo/shared/utils';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://069e0d0f47e3bbe942cbf92de0865d8a@o4506956467011584.ingest.us.sentry.io/4506956470878208',
  integrations: [Sentry.replayIntegration()],
  tracesSampleRate: NEXT_IS_PRODUCTION_ENV ? 1.0 : 0.0,
  replaysSessionSampleRate: NEXT_IS_PRODUCTION_ENV ? 0.1 : 0.0,
  replaysOnErrorSampleRate: NEXT_IS_PRODUCTION_ENV ? 1.0 : 0.0,
  environment: NEXT_ENV,
  enabled: NEXT_IS_PRODUCTION_ENV,
});
